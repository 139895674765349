

export default function MainInformation (){
    return(
        <div id="image-background" style={{width:"100%", marginBottom:"100px"}}>
                <h1 className="informationH1" style={{width:"100%", margin:"auto", marginTop:"50px"}} >Hva er fordelene?</h1>
                <p style={{position:"relative", maxWidth:"1200px", margin:"auto", paddingLeft:"20px", paddingInline:"20px" , fontSize:"25px", lineHeight:"40px", top: "30px", textAlign:"left"}}>
                På vårt bilverksted spesialiserer vi oss utelukkende på Paintless Dent Repair (PDR), en revolusjonerende teknikk som gjenoppretter kjøretøyet til sin opprinnelige tilstand uten behov for tradisjonell karosseriarbeid og omlakkering. Våre dyktige teknikere bruker spesialverktøy for å nøye massere bulker og småskader ut av kjøretøyets metallpaneler. Denne prosessen bevarer fabrikkens finish og opprettholder integriteten til bilens originale lakk.
                </p>
        </div>
    );
}