import AboutPoints from "../components/aboutPoints";
import AboutTop from "../components/aboutTop";
import Menu from "../components/menu"
import GoogleMaps from "../components/googleMaps"
import Footer from "../components/footer"

export default function OmOssPage () {
    return(
        <div id="image-background" style={{overflow:"hidden"}}>
            <Menu/>
            <div id="image-background" style={{width: "100%", height: "125px"}}></div>
            <AboutTop/>
            <AboutPoints/>
            <Footer/>
        </div>
    );
}