import Footer from "./footer";
import Menu from "./menu";

export default function DetailingPage() {
    return (
        <div>
            <Menu />
            <div style={{ height: "138px" }}></div>
            <section className="main-section-article">
                <h1 className="heading-1" style={{ marginTop: "100px", fontWeight: "700", marginBottom: "5px" }}>
                    Detailing av Personbil
                </h1>
                <p style={{ marginTop: 0, marginBottom: 0, fontSize: "14px" }}>August 17, 2024</p>
                <div className="detailing-article"></div>
                <h2 className="heading-2">Innholdet</h2>
                <ul>
                    <li style={{ left: "0" }}>Introduksjon</li>
                    <li style={{ left: "0" }}>Hva innebærer detailing?</li>
                    <li style={{ left: "0" }}>Våre detailing-pakker</li>
                    <li style={{ left: "0" }}>Konklusjon</li>
                </ul>
                <h2 className="heading-2">Introduksjon</h2>
                <p>
                    Detailing er en avansert form for bilpleie som går langt utover vanlig vask og polering. Det handler om å
                    gjenopprette, beskytte og vedlikeholde bilens både utvendige og innvendige overflater, slik at bilen ser
                    ut som ny. Enten du ønsker å bevare bilens verdi, forberede den for salg, eller bare få den til å skinne
                    igjen, er detailing løsningen.
                </p>
                <h2 className="heading-2">Hva innebærer detailing?</h2>
                <p>
                    Detailing innebærer en grundig rengjøring, restaurering og beskyttelse av bilens lakk, interiør, felger,
                    og andre detaljer. Prosessen inkluderer ofte vask, lakkrens, polering, voksing, og behandling av interiør
                    med spesialiserte produkter. Det er en tidkrevende prosess som krever nøyaktighet og kompetanse for å
                    oppnå det beste resultatet.
                </p>
                <h2 className="heading-2">Våre detailing-pakker</h2>
                <p>Vi tilbyr tre detailing-pakker som dekker ulike behov og budsjetter:</p>
                <ul>
                    <li style={{ marginBottom: "35px", left: "0" }}>
                        <strong>Basic Detailing </strong>
                        <ul style={{ marginTop: "10px", marginBottom: "15px", paddingLeft: "20px" }}>
                            <li style={{ marginBottom: "10px", left: "0" }}>Utvendig håndvask</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Enkel lakkrens</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Felgrens</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Voksing av lakk</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Støvsuging av interiør</li>
                        </ul>
                        <p>Passer for deg som ønsker en grunnleggende behandling av bilen med fokus på rens og beskyttelse.</p>
                    </li>
                    <li style={{ marginBottom: "35px", left: "0" }}>
                        <strong>Premium Detailing </strong>
                        <ul style={{ marginTop: "10px", marginBottom: "15px", paddingLeft: "20px" }}>
                            <li style={{ marginBottom: "10px", left: "0" }}>Alt i Basic-pakken</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Full lakkrens med clay bar</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Maskinell polering</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Innvendig rens av seter og tepper</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Behandling av skinninteriør</li>
                        </ul>
                        <p>Ideell for deg som ønsker en mer omfattende behandling som både forbedrer bilens utseende og gir
                            langvarig beskyttelse.</p>
                    </li>
                    <li style={{ marginBottom: "35px", left: "0" }}>
                        <strong>Ultimate Detailing </strong>
                        <ul style={{ marginTop: "10px", marginBottom: "15px", paddingLeft: "20px" }}>
                            <li style={{ marginBottom: "10px", left: "0" }}>Alt i Premium-pakken</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Keramisk coating av lakk</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Ozonbehandling for luktsanering</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Detaljert rengjøring av motorrom</li>
                            <li style={{ marginBottom: "10px", left: "0" }}>Vask og behandling av felger med keramisk coating</li>
                        </ul>
                        <p>For deg som ønsker det aller beste for bilen din, med maksimal beskyttelse og enestående finish.</p>
                    </li>
                </ul>
                <h2 className="heading-2">Konklusjon</h2>
                <p style={{ marginBottom: "100px" }}>
                    Detailing er den ultimate måten å ta vare på bilen din på, og sikrer at den ser fantastisk ut både utvendig
                    og innvendig. Uansett hvilket behov du har, har vi en pakke som passer for deg. Vår ekspertise og bruk av
                    kvalitetsprodukter gir deg et resultat som varer, og som du vil være stolt av. Bestill din detailing i dag
                    for å gi bilen din den pleien den fortjener!
                </p>
            </section>
            <Footer />
        </div>
    );
}