import Footer from "./footer";
import Menu from "./menu";

export default function ArticlePage(){
    return (
        <div>
             <Menu/>  
             <div style={{height:"138px"}}></div>  
             <section className="main-section-article">
                <h1 className="heading-1" style={{marginTop:"100px", fontWeight:"700", marginBottom:"5px"}}>Paintless Dent Repair (PDR)</h1>
                <p style={{marginTop:0, marginBottom:0, fontSize:"14px"}}>Oktober 13, 2023</p>
                <div className="background-article"></div>
                <h2 className="heading-2">Innholdet</h2>
                <ul>
                    <li style={{left:"0"}}>Introduksjon</li>
                    <li style={{left:"0"}}>Hva er Lakkeringsfri bulkoppretting?</li>
                    <li style={{left:"0"}}>Fordelene ved PDR</li>
                    <li style={{left:"0"}}>Hvordan fungerer Lakkeringsfri bulkoppretting?</li>
                    <li style={{left:"0"}}>Konklusjon</li>
                </ul>
                <h2 className="heading-2">Introduksjon</h2>
                <p>Å opprettholde bilens estetiske tilstand kan være en utfordrende oppgave, 
                    spesielt når uhell og bulker oppstår. Tradisjonell bilreparasjon kan være kostbart og 
                    tidkrevende, med behov for lakkering og materialer som kan være skadelige for miljøet. 
                    Heldigvis har en banebrytende teknikk kalt Paintless Dent Repair (PDR) kommet til unnsetning
                     for både bileiere og miljøet. PDR er en effektiv og bærekraftig måte å reparere bulker på biler
                      uten å måtte lakkere over skaden. </p>
                <h2 className="heading-2">Hva er Lakkeringsfri bulkoppretting?</h2>
                <p>PDR er en teknikk for å reparere bulker, hakk og små deformasjoner på en bil uten å påføre ny lakk.
                     Denne metoden har revolusjonert bilreparasjonsindustrien ved å tilby en rekke fordeler som gjør den 
                     til et stadig mer populært valg for bileiere.</p>
                <h2 className="heading-2">Fordelene ved PDR</h2>
                <ul>
                    <li style={{marginBottom:"35px", left:"0"}}>
                        <strong>Raskere reparasjoner:</strong> En av de mest tiltalende aspektene ved PDR er hastigheten. 
                        Denne metoden gjør det mulig å reparere bulker betydelig raskere sammenlignet med 
                        tradisjonell lakkering.</li>
                    <li style={{marginBottom:"35px", left:"0"}}><strong>Kostnadseffektivt:</strong> PDR er vanligvis mye rimeligere enn tradisjonell lakkering.
                         Med minimale materialkostnader og mindre arbeidsintensive prosesser, 
                         gir PDR en kostnadseffektiv løsning for å fikse mindre bulker og hakk. Dette kan 
                         hjelpe bileiere med å spare betydelige beløp på reparasjonskostnader.</li>
                    <li style={{marginBottom:"35px", left:"0"}}><strong>Bevarer original lakk:</strong> En viktig fordel med PDR er at den bevarer bilens opprinnelige lakk.
                         Dette er spesielt verdifullt for eiere av verdifulle eller antikke biler, da original 
                         lakk bidrar til å opprettholde bilens verdi og autentisitet. I tillegg gir dette en
                          visuell kontinuitet som tradisjonelle reparasjoner med ny lakk ikke kan oppnå.</li>
                    <li style={{marginBottom:"35px", left:"0"}}><strong>Miljøvennlig:</strong> PDR er en mer miljøvennlig metode for bilreparasjon. Fordi den ikke krever 
                        lakkering og bruk av kjemikalier, reduseres påvirkningen på miljøet betydelig. Dette gjør PDR
                         til et bærekraftig alternativ for de som ønsker å minimere sin miljøpåvirkning.</li>
                </ul>
                <h2 className="heading-2">Hvordan fungerer Lakkeringsfri bulkoppretting?</h2>
                <p>PDR-prosessen krever spesialverktøy og kunnskap. Reparatøren bruker disse verktøyene 
                    til å komme til bulken fra innsiden av panelet og deretter manipulere og massere skaden
                     forsiktig tilbake til sin opprinnelige form. Dette gjøres uten behov for lakkering eller fyllstoff. 
                     PDR er spesielt egnet for mindre bulker som ikke har skadet lakkoverflaten. For større eller mer
                      alvorlige skader, kan tradisjonell reparasjon fremdeles være nødvendig.</p>
                <h2 className="heading-2">Konklusjon</h2>
                <p style={{marginBottom:"100px"}}>Lakkeringsfri bulkoppretting (PDR) er en banebrytende teknikk som gir en rask, kostnadseffektiv, 
                    og bærekraftig løsning for å reparere bulker på biler. Denne metoden har blitt stadig mer 
                    populær blant bileiere som ønsker å opprettholde bilens estetiske verdi og samtidig redusere
                     miljøpåvirkningen. Ved å bevare original lakk og minimere behovet for lakkering, er PDR et skritt 
                     i retning av en mer bærekraftig bilreparasjonsindustri.</p>

             </section>
             <Footer/>       
        </div>
    );
}