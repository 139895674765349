

export const ReviewData = [
    {
        name:"Adrian C",
        date:"14. April 2023",
        points:5,
        id:"dot1",
        text:
          'Marcin har gjort en kjempefin jobb med reparasjon av en bulk på bilen. Jeg er fornøyd!'
    },
    {
      name:"Pawel G",
      date:"3. November 2023",
      points:5,
      id:"dot2",
      text:
        'God service og flott verksted'
    },
    {
      name:"Ernest S.",
      date:"9. August 2022",
      points:5,
      id:"dot3",
      text:
        'Kjempe bra jobb! Flott service'
    },
    {
      name:"Krist J.",
      date:"21. Juni 2023",
      points:5,
      id:"dot4",
      text:
        ''
    },
    {
      name:"Rafal M.",
      date:"12. Juni 2023",
      points:5,
      id:"dot5",
      text:
        ''
    },
    {
      name:"Aleksandra S.",
      date:"10. Januar 2023",
      points:5,
      id:"dot6",
      text:
        ''
    },
    {
      name:"Andelina I",
      date:"15. Januar 2023",
      points:5,
      id:"dot7",
      text:
        ''
    },
    {
      name: "Bulent S.",
      date:"25. Mars 2023",
      points:5,
      id:"dot8",
      test:''
    }
  ];