

export default function AboutTop() {
    return (
        <>
        <div className="about-top-background">
            <div  className="about-top-inner">
                <h1 className="big-h1" style={{marginTop: "50px"}}>Vi tar vare på <br></br>bilen din!</h1>
                <div>
                    <p className="om-oss-text" id="om-oss-text1">
                    PDR Partner er et verksted i Lambertseter området. <br></br>
                    Vi tilbyr lakkering, reparering av små og store skader og PDR. <br></br><br></br>

                    PDR Partner har mye erfaring med tradisjonell lakkering,<br></br>
                    men også kjenner godt til nye teknikker som PDR (Lakkeringsfri bulkoppretting).<br></br>
                    PDR gir mulighet for reparasjon av bulker uten lakkering. <br></br>
                    Skader kan repareres så lenge lakken på bilen ikke er ødelagt.<br></br>
                    </p>
                    <p className="om-oss-text" id="om-oss-text2">
                    PDR Partner er et verksted i <br></br>
                     Lambertseter området. <br></br>
                    Vi tilbyr lakkering, reparering <br></br>
                     av små og store skader og PDR. <br></br><br></br>

                    PDR Partner har mye erfaring <br></br>
                    med tradisjonell lakkering,<br></br>
                    men også kjenner godt til nye teknikker <br></br>
                    som PDR (Lakkeringsfri bulkoppretting).<br></br>
                    PDR gir mulighet for reparasjon av<br></br> 
                    bulker uten lakkering. <br></br>
                    Skader kan repareres så lenge <br></br>
                    lakken på bilen ikke er ødelagt.<br></br>
                    </p>
                </div>
            </div>
        </div>
        </>
    );
}