import React, { useEffect, useState } from "react";
import { motion, useAnimationControls } from "framer-motion";
import Field from "./field";
import imageCompression from 'browser-image-compression';
import CarHero from "./CarHero";


const Form = () => {

    const [isModalOpen, setIsModalOpen] = useState(false);

    const [regnummer, setRegnummer] = useState("");
    const [error_regnummer, setErrorRegnummer] = useState("")

    const [phone, setPhone] = useState("");
    const [error_phone, setErrorPhone] = useState("");

    const [email, setEmail] = useState("");
    const [error_email, setErrorEmail] = useState("");

    const [message, setMessage] = useState("");
    const [error_message, setErrorMessage] = useState("");

    const [clickPositions, setClickPositions] = useState({});

    const [productPhotos, setProductPhotos] = useState([]);
    const [skade, setSkade] = useState([])
    const [error_skade, setErrorSkade] = useState([]);

    useEffect(()=>{
        console.log(skade)
    }, [skade])

    const [image, setImage] = useState("./images/stage1.png");
    const [loading, setLoading] = useState(false)
    const controls = useAnimationControls();

    const nesteSide = (e) => {
        e.preventDefault();
        controls.start({
            right: "0%"
        });
        setImage("./images/stage1.png");
    };

    const nesteSide1 = (e) => {
        e.preventDefault();
        if (regnummer != "") {
            controls.start({
                right: "20%"
            });
            setErrorRegnummer("")
            const elements = document.querySelectorAll('.list_skade'); // Select all elements with class 'myDiv'
            elements.forEach(element => {
                element.style.display = 'block'; // Set display to 'none' for each element
            });
            setImage("./images/stage2.png");
        } else {
           setErrorRegnummer("Skriv inn reg. nummeret her.")
        }
    };

    const nesteSide2 = (e) => {
        e.preventDefault();
       
        if(!skade.length == 0){
            setImage("./images/stage3.png");
            const elements = document.querySelectorAll('.list_skade'); // Select all elements with class 'myDiv'
            elements.forEach(element => {
                element.style.display = 'none'; // Set display to 'none' for each element
            });
            controls.start({
                right: "40%"
            });
            

        } else {
            setErrorSkade("Du må legge til minst ett skade")
        }
           
    };

    const nesteSide3 = (e) => {
        e.preventDefault();
        setImage("./images/stage4.png");
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        if (email == "") {
            setErrorEmail("Skriv inn e-post adresse her.")
        } else if (!emailRegex.test(email)) {
            setErrorEmail("Skriv riktig e-post")
        } else {
            controls.start({
                right: "60%"
            });
        }       
    };


    const changeValue = (field, value) => {
        switch (field) {
            case "regnummer":
                setRegnummer(value);
                break;
            case "phone":
                setPhone(value);
                break;
            case "email":
                setEmail(value);
                break;
            case "message":
                setMessage(value);
                break;
                case "ProductPhotos":
                    setProductPhotos(prevPhotos => [...prevPhotos, ...value]);
                    break;
            default:
                break;
        }
    };

    const onSubmit = async (e) => {
        e.preventDefault(); // Prevent the default form submission
       
        
        const phoneRegex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
        if (!phone) {
            setErrorPhone('Skriv telefon nummer');
            return
          } 

          setLoading(true)
        
        try {
           
            const uploadPromises = await Promise.all(
                skade.map(async (element) => {
                    console.log(element);
                    return Promise.all( // Await all inner promises
                        element.photos.map(async (picture) => {
                            try {
                                // Compression options
                                const options = {
                                    maxSizeMB: 1,          // Target 1MB or less
                                    maxWidthOrHeight: 1920, // Optionally resize to maintain quality and reduce file size
                                    useWebWorker: true      // Use web worker for faster processing
                                };
                            
                                // Compress the image
                                const compressedBlob = await imageCompression(picture, options);
                            
                                // Generate a random filename to avoid strange characters
                                const randomFileName = `img_${Math.random().toString(36).substring(2, 15)}_${Date.now()}.${picture.type.split('/')[1]}`; // Random filename with the original extension
                                const compressedFile = new File([compressedBlob], randomFileName, { type: picture.type });
                            
                                const formData = new FormData();
                                formData.append('_payload', '{}');
                                formData.append('file', compressedFile);
                            
                                const response = await fetch("https://admin.pdrpartner.no/api/media?depth=0&fallback-locale=null", {
                                    method: "POST",
                                    headers: {
                                        "accept": "*/*",
                                        "accept-language": "en",
                                    },
                                    body: formData
                                });
                            
                                if (response.ok) {
                                    const result = await response.json();
                                    return {
                                        filename: result.doc.filename,
                                        path: `https://admin.pdrpartner.no${result.doc.url}`
                                    };
                                } else {
                                    console.error('Error:', response.status, response.statusText);
                                    return null; // or handle error as needed
                                }
                            } catch (error) {
                                console.error('Compression error:', error);
                                return null; // or handle error as needed
                            }
                            
                        })
                    );
                })
            );
            
            console.log(uploadPromises);
            let skade_obj = [] 
            skade.map((element, index) =>{
                skade_obj.push({
                    image: element.image,
                    message: element.message,
                    x: element.x,
                    y: element.y,
                    photos: uploadPromises[index]
                })
            })

            const data = {
                reg_number: regnummer,
                phone: phone,
                email: email,
                skader: skade_obj
            };

            console.log(data)

    
            // Wait for all image uploads to complete
            const images = await Promise.all(uploadPromises);
    
            // Filter out any null results in case some uploads failed
            //data.pictures = images.filter(image => image !== null);
    
            console.log("Data", JSON.stringify(data));
    
            // Now submit the form with the updated data object
            const req = await fetch('https://admin.pdrpartner.no/api/form', {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                },
                body: JSON.stringify(data),
            });
    
            if (req.ok) {
                console.log('Form submitted successfully');

                window.location.href = "/takk-for-henvendelsen";
            } else {
                console.error('Error:', req.status, req.statusText);
            }
    
        } catch (error) {
            console.error('Error submitting form:', error.message);
        }
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };
    
    const removeSkade = (e,index) => {
        e.preventDefault();
        console.log(clickPositions)
        setSkade((prevSkade) => prevSkade.filter((_, i) => i !== index));
    }

    return (
        <form onSubmit={onSubmit} noValidate>
            {!loading?
                <div className="transparent-background">
                <motion.div animate={controls} className="inside-background">
                    <div className="sliding-column">
                        <h1 className="form-h1">Reg. nummer</h1>
                        <Field
                            text="regnummer"
                            default="f.eks RB 55023"
                            name="regnummer"
                            value={regnummer}
                            onChange={(e) => changeValue('regnummer', e.target.value)}
                        />
                      <p>{error_regnummer}</p>
                        <button className="black-button" style={{ marginTop: "25px" }} onClick={nesteSide1}>
                            Neste
                        </button>
                    </div>
                    <div className="sliding-column">
                    <h1 className="form-h1">Marker der skadene befinner seg</h1>
                    <section className="list_skade">
                    <CarHero clickPositions={clickPositions} setClickPositions={setClickPositions} skade={skade} setSkade={setSkade} setIsModalOpen={setIsModalOpen} isModalOpen={isModalOpen} productPhotos={productPhotos} changeValue={changeValue}/>
                    </section>
                    
                        <div className="buttons-form">
                        <p></p>
                        {skade.length > 0 ? (
                      <div style={{maxWidth: "700px", margin: "auto", padding: "10px"}}>
                        <p>Oppsumering</p>
                      {skade.map((element, index) => (
                          <div key={index} className="list_skade" style={{display: "flex", flexDirection: "row", border:"2px solid #FF4C00",borderRadius: "10px", marginBottom:"10px" , alignItems: "center"}}>
                              <p style={{marginRight: "20px", width: "70px"}}>Skade {index + 1}</p>
                              <div style={{display: "flex", overflowX: "auto", whiteSpace: "nowrap", maxWidth: "500px"}}>
                                  {element.photos.map((photo, photoIndex) => (
                                      <img
                                          key={photoIndex}
                                          style={{maxWidth: "150px", margin: "10px", flexShrink: 0}}
                                          src={URL.createObjectURL(photo)}
                                          alt={`Skade ${index + 1} photo ${photoIndex + 1}`}
                                      />
                                  ))}
                              </div>
                              <button  onClick={(e) => removeSkade(e, index)} style={styles.closeButton}>
                                &times;
                            </button>
                          </div>
                      ))}
                  </div>
                        ) : (
                        <></>
                        )}
                            <p>{error_skade}</p>
                            <button className="black-button" id="tilbake-button" style={{ marginTop: "25px" }} onClick={nesteSide}>
                                Tilbake
                            </button>
                            <button className="black-button" onClick={nesteSide2}>
                                Neste
                            </button>
                        </div>
                    </div>
                    <div className="sliding-column">
                        <h1 className="form-h1">Email</h1>
                        <Field
                            text="email"
                            default="Email"
                            name="email"
                            value={email}
                            onChange={(e) => changeValue('email', e.target.value)}
                        />
                        <p>{error_email}</p>
                        <div className="">
                            <button className="black-button" style={{ marginTop: "25px" }} id="tilbake-button" onClick={nesteSide1}>
                                Tilbake
                            </button>
                            <button className="black-button" onClick={nesteSide3}>
                                Neste
                            </button>
                        </div>
                    </div>
                    <div className="sliding-column">
                    <h1 className="form-h1">Tlf. nummer</h1>
                        <Field
                            text="phone"
                            default="Telefon nummer"
                            name="phone"
                            value={phone}
                            onChange={(e) => changeValue('phone', e.target.value)}
                        />
                        <div>
                        <p>{error_phone}</p>
                            <button className="black-button" id="tilbake-button" style={{ marginTop: "25px" }} onClick={nesteSide2}>
                                Tilbake
                            </button>
                            <button className="black-button" style={{ marginTop: "50px" }} >
                                Send
                            </button>
                        </div>
                    </div>

                </motion.div>
            </div>:
                <div className="transparent-background">
                    <div class="loader"></div>
                </div>
                
            }
            
        </form>
    );
};

const styles = {
    closeButton: {
        position: "relative",
        background: "none",
        border: "none",
        fontSize: "24px",
        cursor: "pointer",
        color: "#000",
        width:"fit-content",
        left: "5px"
    },
};


export default Form;
