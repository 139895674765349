import ReviewTemplate from "../components/ReviewTemplate";
import Contact from "../components/contact";
import Footer from "../components/footer";
import MainInformation from "../components/mainInformation";
import MainService from "../components/mainService";
import Menu from "../components/menu";
import WelcomeVideo from "../components/welcomeTop";
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import { ReviewData } from "../components/ReviewData";
import AboutPoints from "../components/aboutPoints";


export default function HomePage (){

    const FIRST_IMAGE = {
        imageUrl: "./images/bmw2.png"
      };
      const SECOND_IMAGE = {
        imageUrl: './images/bmw.png'
      };

      const FIRST_IMAGE_BLACK = {
        imageUrl: './images/lakkeringsfri_black_for.png'
      };

      const SECOND_IMAGE_BLACK = {
        imageUrl: './images/lakkeringsfri_black2.png'
      };

      const FIRST_IMAGE_SKODA = {
        imageUrl: './images/lakkeringsfri_skoda_for.png'
      };

      const SECOND_IMAGE_SKODA = {
        imageUrl: './images/lakkeringsfri_skoda.png'
      };

      const delimiterIconStyles = {
        width: '38px',
        height: '38px',
        backgroundSize: 'cover',
        borderRadius: 'none',
        backgroundImage: 'url(./images/Slide.png)',
        backgroundColor: '#FF4C00',
        borderRadius:'0px',
        transform: 'scaleX(1) scaleY(0.5) rotate(45deg)'
     }

    return(
        <div style={{overflow:"hidden", textAlign:"center"}}>
            <Menu/>
                <WelcomeVideo/>
                <MainInformation/>
                <AboutPoints/>
                <MainService/>
                <div  style={{maxWidth:"1440px", margin:"auto"}}>
                    <h2 className="muligheter">Se effektene</h2>
               
                <section className="before-after" style={{marginTop:"100px", marginBottom:"0px"}}>
                    <section style={{boxShadow:"20px 20px 5px lightgrey"}}>
                    <ReactBeforeSliderComponent
                        firstImage={FIRST_IMAGE}
                        secondImage={SECOND_IMAGE}
                        currentPercentPosition = "75"
                        delimiterIconStyles = {delimiterIconStyles}
                    />
                    </section>
                    <div className="before-after-text">
                        <div className="for">Før</div>
                        <div className="for" id="etter">Etter</div>
                    </div>
                </section>
                <section className="before-after" style={{marginTop:"100px", marginBottom:"0px"}}>
                    <section style={{boxShadow:"20px 20px 5px lightgrey"}}>
                    <ReactBeforeSliderComponent
                        firstImage={SECOND_IMAGE_BLACK}
                        secondImage={FIRST_IMAGE_BLACK}
                        currentPercentPosition = "75"
                        delimiterIconStyles = {delimiterIconStyles}
                    />
                </section>      
                   
                    <div className="before-after-text">
                        <div className="for">Før</div>
                        <div className="for" id="etter">Etter</div>
                    </div>
                </section>

                <section className="before-after" style={{marginTop:"100px", marginBottom:"0px"}}>
                    <section style={{boxShadow:"20px 20px 5px lightgrey"}}>
                    <ReactBeforeSliderComponent
                        firstImage={SECOND_IMAGE_SKODA}
                        secondImage={FIRST_IMAGE_SKODA}
                        currentPercentPosition = "75"
                        delimiterIconStyles = {delimiterIconStyles}
                    />
                    </section>

                   
                    <div className="before-after-text">
                        <div className="for">Før</div>
                        <div className="for" id="etter">Etter</div>
                    </div>
                </section>
                <h2 style={{fontSize:"25px", width:"70%", margin:"auto", textAlign:"left", marginTop:"100px"}}>Anmeldelsene våre fra Google</h2>
                <ReviewTemplate slides={ReviewData}/>
                </div>
                
                <Contact/>
            <Footer/>
        </div>
            
    );
}