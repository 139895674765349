import Menu from "../components/menu";
import SmallPicture from "../components/smallPicture";
import React, { useState } from "react";

export default function ProsjekterPage(){

    const [image, setImage] = useState("./images/mg.png");

    return(
        <div style={{overflowX: "hidden"}}>
        <Menu/>
        <div className="grid-prosjekter">
            <SmallPicture
            width="512px"
            height="316px"
            picture={image}
            text="MG prosjekt"
            font="23px"
            topText="10px"
            />
            <SmallPicture
            width="512px"
            height="316px"
            picture={image}
            text="tell"
            font="23px"
            topText="10px"
             />
             <SmallPicture
            width="512px"
            height="316px"
            picture={image}
            text="tell"
            font="23px"
            topText="10px"
             />
             <SmallPicture
            width="512px"
            height="316px"
            picture={image}
            text="tell"
            font="23px"
            topText="10px"
             />
        </div>
        
        </div>
    );
}