import {Link } from "react-router-dom";
import React, {useState} from "react";


const ContactForm = ({onSave, user={}}) =>{

    const [userData, setUserData] = useState(user);
    const [errors, setErrors] = useState({});
    const {email, phone, message} = userData;

    const handleChange = (event) => {
        const {name, value} = event.target;
        setUserData((prevData) => ({...prevData, [name]: value }));
        setErrors({});
    };

    const sendEmail = async() =>{
        console.log(userData);
        const response = await fetch(
                "https://admin.pdrpartner.no/api/contact",
                {
                    method: 'POST',
                    body: JSON.stringify({email: userData.email, phone: userData.phone, message: userData.message}),
                    headers: { 'Content-Type': 'application/json' }
                }
        );
        let resdata = await response.text();
        console.log(response, resdata);

        window.location.replace('https://pdrpartner.no/takk-for-henvendelsen');

    }


    return (
        <div className="service-container" id="contact-out">
            <div className="contact-left">
                <h1 className='ernest2' >Skade på bilen?</h1>
                <Link to={"/pristilbud"}><button className='black-button' id='ernest3' style={{marginTop: "20px"}}>Få et pristilbud</button></Link>
            </div>
            <div className="contact-right" >
                <div className="form-contact">
                    <h2 className='eller-kontakt' style={{textAlign:"left"}}> Eller legg igjen kontaktinformasjonen din</h2>
                    <h2 style={{textAlign:"left"}} className="contact-label">Email</h2>
                    <input className='input2' id="display" name="email" onChange={handleChange}></input>
                    <h2 style={{textAlign:"left"}} className="contact-label">Tlf. nummer</h2>
                    <input className='input2' id="display" name="phone"  onChange={handleChange}></input>
                    <h2 style={{textAlign:"left"}} className="contact-label">Melding (Valgfri)</h2>
                    <textarea className='input2' style={{float:"left", height:"100px"}} name="message"  onChange={handleChange}></textarea>
                    <button className='send-information' onClick={sendEmail}>Send</button>
                </div>
                        
                        
            </div>
        </div>
    );
}

export default ContactForm;
