import ContactPage from "./ContactPage"
import HomePage from "./HomePage"
import OmOssPage from "./OmOssPage"
import Pristilbud from "./Pristilbud"
import ProsjekterPage from "./ProsjekterPage"
import Prosjekt from "./Prosjekt"
import Personvern from "./personvern"
import TakkPage from "./TakkPage"
import ArticlePage from "../components/articlePage"

const routes = [
	{
		path: "/",
		element: <HomePage />
	},
	{
		path: "/pristilbud",
		element: <Pristilbud />
	},
	{
		path: "/kontakt",
		element: <ContactPage/>
	},
	{
		path: "/om-oss",
		element: <OmOssPage/>
	},
	{
		path: "/prosjekter",
		element: <ProsjekterPage/>
	},
	{
		path: "/prosjekt",
		element: <Prosjekt/>
	},
	{
		path:"/personvern",
		element: <Personvern/>
	},
	{
		path:"/takk-for-henvendelsen",
		element: <TakkPage/>
	},
	{
		path:"/lakkeringsfri-bulkoppretting",
		element: <ArticlePage/>
	}
	
]

export default routes
