import React, { useEffect, useState } from "react";
import { fetchCars } from "./fetchCar";
import Modal from "./modal";
import car1 from '../assets/model/car-front.png'
import car4 from '../assets/model/car-left.png'
import car3 from '../assets/model/car-back.png'
import car2 from '../assets/model/car-right.png'

function CarHero({ changeValue, productPhotos, setIsModalOpen, isModalOpen, skade, setSkade }) {
  const angles = ["1", "2", "3", "4"];
  const cars = [car1, car2, car3, car4];
  const [currentAngleIndex, setCurrentAngleIndex] = useState(0);
  const [xPosition, setX] = useState();
  const [yPosition, setY] = useState();
  const [clickPositions, setClickPositions] = useState({});
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener('resize', handleResize);

    // Cleanup the event listener on unmount
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);
  const handleNextAngle = (e) => {
    e.preventDefault();
    setCurrentAngleIndex((prevIndex) => (prevIndex + 1) % cars.length);
  };

  const handlePrevAngle = (e) => {
    e.preventDefault();
    setCurrentAngleIndex((prevIndex) => (prevIndex - 1 + cars.length) % cars.length);
  };

  const handleImageClick = (e) => {
    const rect = e.target.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;

    setX(x)
    setY(y)

    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  return (
    <div>
      <div
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          width: "100%",
          padding: "10px",
          flexDirection: windowWidth <= 1000 ? "column" : "row", // Default to row for larger screens
        }}
      >
        <button
          onClick={handlePrevAngle}
          style={{
            margin: "10px",
            padding: "10px",
            fontSize: "16px",
          }}
        >
          Snu til Venstre
        </button>
        <div
          style={{
            position: "relative",
            width: "100%",
            maxWidth: "700px",
            display: "flex",
            justifyContent: "center",
          }}
        >
          <img
            style={{
              width: "100%",
              maxWidth: "700px",
              cursor: "crosshair",
            }}
            src={cars[currentAngleIndex]}
            alt="Car"
            onClick={handleImageClick}
          />
          {console.log(skade)}
          {(skade || []).map((pos, index) => 
            pos.image === currentAngleIndex ? (
              <div
                key={index}
                style={{
                  position: "absolute",
                  top: pos.y - 10,
                  left: pos.x - 10,
                  width: "12px",
                  height: "12px",
                  padding: "5px",
                  borderRadius: "50%",
                  backgroundColor: "orange",
                  pointerEvents: "none",
                }}
              > 
                <div
                  style={{
                    width: "100%",
                    height: "100%",
                    backgroundColor: "white",
                    borderRadius: "50%",
                    fontSize: "10px",
                    display: "flex",
                    alignItems: "center",
                    justifyContent: "center",
                  }}
                >
                  {index + 1}
                </div>
              </div>
            ) : null // Change to null to avoid rendering anything when the condition is false
          )}

        </div>
        <button
          onClick={handleNextAngle}
          style={{
            margin: "10px",
            padding: "10px",
            fontSize: "16px",
          }}
        >
          Snu til Høyre
        </button>
      </div>
      <Modal
        fieldOnChange={(files) => changeValue("ProductPhotos", files)}
        FieldValue={productPhotos}
        show={isModalOpen}
        onClose={handleCloseModal}
        setSkade={setSkade}
        setClickPositions={setClickPositions}
        currentAngleIndex={currentAngleIndex}
        x={xPosition}
        y={yPosition}
        angles={angles}
      />

      {/* Responsive media query */}
      <style jsx>{`
        @media (max-width: 768px) {
          div > div {
            flex-direction: column; /* Stack vertically on smaller screens */
          }

          button {
            margin: 5px; /* Reduce button margins on small screens */
          }
        }
      `}</style>
    </div>
  );
}

export default CarHero;
