import Menu from "../components/menu";
import Footer from "../components/footer";

export default function TakkPage () {

    return(
        <div>
            <Menu/>
            <div style={{height:"138px"}}></div>
            <h1 className="thank-you-message">Takk for henvendelsen!</h1>
            <h3 className="thank-you-message" style={{marginTop:"50px", marginBottom:"200px"}}>Vi svarer på forespørsmålet ditt så fort som mulig
            </h3>

            <Footer/>
        </div>
    );

}