import Menu from "../components/menu";
import Footer from "../components/footer"

export default function Personvern (){
    return (
            <div>
            <Menu/>
            <div style={{width:"100%", height:"150px"}}></div>
            <h1 >Personvern erklæring <br></br><br></br></h1>
            <p>Denne erklæringen beskriver hvordan PDR Partner Marcin Strychalski samler inn og bruker personopplysninger. <br></br>
                <strong>Bedriftsinformasjon:</strong> <br></br>
                Marcin Strychalski PDR Partner <br></br>
                Org.nr: 923654062 <br></br>
                Tlf: +47 925 65 764 <br></br>
                https://www.pdrpartner.no <br></br>
                verksted@pdrpartner.no <br></br>
                Adresse: Grini Næringspark 1, 1367 Østerås <br></br> </p>

                <h2>Behandlingsansvarlig<br></br></h2>
                <p>  PDR Partner Marcin Strychalski er behandlingsansvarlig for virksomhetens behandling av personopplysninger.  <br></br>
                    Erklæringen inneholder opplysninger du har krav på når det samles inn opplysninger fra   <br></br>
                    nettstedet vårt (personopplysningsloven § 19) og generell informasjon om hvordan  <br></br>
                    vi behandler personopplysninger (personopplysningsloven § 18 1. ledd).  <br></br></p>
                <h2 class="text_header">Hva samler vi inn og hvorfor?<br></br></h2>
                <p class="text">Formålet med behandlingen av personopplysninger er å opprette kontakt mellom potensielle kunder og leverandører. <br></br>
                    Brukeren legger selv inn sine opplysninger, og disse blir brukt til å ta kontakt for konsultasjon,<br></br>
                    tilbud eller annen salgsrelatert kontakt. Vi samler også inn informasjon om trafikk og bruksmønster på siden.<br></br>
                    Dette gjøres ved hjelp av tredjepartsverktøy, nevnt under.<br></br><br></br>

                    <strong> Analyseverktøy </strong><br></br>
                    På våre nettsider bruker vi analyseverktøyet Google Analytics.<br></br>
                    Dette er et verktøy som gir oss innsikt i blant annet hvordan brukere beveger seg på nettsiden,<br></br>
                    hvor lenge de oppholder seg på en side og hvilken nettside de var på før https://www.lambertseterbilverksted.no,<br></br>
                    ved hjelp av informasjonskapsler. Dette verktøyet benytter vi for å forbedre våre nettsider og dens brukeropplevelse,<br></br>
                    forstå brukermønstre, måle effektivitet av markedsaktiviteter og analysere statistikk om trafikk på siden.<br></br>
                    Standardinnstillingen til Google Analytics gjør at verktøyet registrerer besøkendes IP-adresser.<br></br>
                    Ifølge Datatilsynet er en IP-adresse definert som en personopplysning fordi den kan spores tilbake til<br></br>
                    en bestemt maskinvare og dermed til en enkeltperson. Vi benytter oss derfor av IP-anonymisering i Google Analytics.<br></br>
                    På den måten anonymiseres brukerens IP og den blir ikke lagret eller sporbar.<br></br>
                    Data registrert gjennom Google Analytics lagres på Googles servere i USA. Opplysninger registrert med Google Analytics<br></br>
                    er underlagt Googles retningslinjer for personvern.<br></br><br></br>

                    <strong>Informasjonskapsler (cookies) </strong><br></br>
                    Ved besøk på https://www.lambertseterbilverksted.no vil det bli lagret informasjonskapsler på brukerens enhet.<br></br>
                    Informasjonskapsler, eller cookies på engelsk, er små tekstfiler som plasseres på din datamaskin når du laster ned<br></br>
                    en nettside. Disse tekstfilene brukes for å kjenne igjen brukeren og gi han/henne en mer brukertilpasset opplevelse.<br></br>
                    Dette kan for eksempel bety hurtigere sideinnlasting, forhåndsutfylte felter, lagret språkvalg eller andre funksjoner<br></br>
                    for å gjøre hjemmesiden mer brukervennlig. Lagring av opplysninger og behandling av disse opplysningene er ikke tillatt<br></br>
                    med mindre bruker både har blitt informert om og har gitt sitt samtykke til behandlingen. Brukeren skal få vite<br></br>
                    om og godkjenne hvilke opplysninger som behandles, hva formålet med behandlingen er og hvem som behandler opplysningene, jf. ekomloven § 2-7b.<br></br>
                    Brukeren kan selv velge om lagring av informasjonskapsler skal tillates eller ikke.<br></br>
                    Dette gjøres i brukerens nettleser. Klikk her for framgangsmåte.<br></br><br></br>

                    <strong> Informasjonskapsler for persontilpasset markedsføring</strong><br></br>
                    I vår markedsføring benytter vi oss av digitale kanaler som Googles annonsenettverk og Facebook.<br></br>
                    Vi ønsker at vår markedsføring skal være nyttig og ha verdi for brukerne som måtte se den. Derfor benytter vi oss av persontilpasset markedsføring.<br></br>
                    For at vi skal kunne målrette til spesifikke brukere på denne måten, bruker vi Facebook Pixel og Google Remarketing Tag. Disse verktøyene gir hver bruker<br></br>
                    en unik og ikke-sporbar ID ved å lagre en informasjonskapsel på brukerens enhet.<br></br>
                    På denne måten kan brukeren kjennes igjen på andre nettsider eller apper og motta persontilpasset markedsføring.<br></br>
                    <strong>Kontaktskjema</strong><br></br>
                    Kontaktskjema på nettsiden <a href="https://www.pdrpartner.no">https://www.pdrpartner.no</a> brukes til å sende inn forespørsler om tilbud, bestillinger<br></br>
                    eller ønske om kontakt med en salgs- eller kundeansvarlig, og ved andre spørsmål vedrørende PDR Partner Marcin Strychalski sine<br></br>
                    tjenester og virke. Forespørselen slettes etter at saken er avsluttet og spørsmålet er besvart, senest 30 dager etter.<br></br><br></br>
                </p>
                <h2 class="text_header">Opplysninger du selv oppgir<br></br></h2>
                <p class="text">Ved bruk av kontaktskjemaet legger brukeren selv inn personopplysninger. Disse opplysningene inkluderer navn, <br></br>
                    e-post og telefonnummer og reg. nummer.<br></br>
                    Opplysningene du selv oppgir på <a href="https://www.pdrpartner.no">https://www.pdrpartner.no</a> lagres i en database.<br></br>
                    <a href="https://www.pdrpartner.no">https://www.pdrpartner.no</a> benytter seg av https-protokoll for overføring av informasjon. <br></br>
                    Dette betyr at all informasjon du oppgir på siden blir sendt kryptert.<br></br>
                </p>
                <h2 class="text_header">Informasjonssikkerhet<br></br></h2>
                <p class="text">PDR Partner Marcin Strychalski plikter å behandle personopplysninger på en sikker måte. <br></br>
                    PDR Partner Marcin Strychalski etterlever og opererer etter Datatilsynets prinsipper for informasjonssikkerhet.<br></br>
                    <strong>Konfidensialitet</strong> - hindre uvedkommende i å få tilgang på opplysningene<br></br>
                    <strong>Integritet</strong>– ingen uautorisert eller utilsiktet endring av opplysninger<br></br>
                    <strong>Tilgjengelighet</strong> – opplysningene er tilgjengelige når tilgang er nødvendig<br></br>
                    For å sikre dine personopplysninger er det kun autoriserte personer, underlagt taushetsplikt, <br></br>
                    som har tilgang til opplysningene. Tilgang er begrenset med et sterkt og unikt passord. All overføring av personopplysninger <br></br>
                    skjer gjennom kryptert tilkobling, så langt dette er mulig. PDR Partner Marcin Strychalski har rutiner for utlevering av opplysninger<br></br>
                    dersom dette skulle bli forespurt.<br></br>
                </p>
                <h2 class="text_header">Brukerens rettigheter<br></br></h2>
                <p class="text">Alle som spør har rett på grunnleggende informasjon om behandlinger av personopplysninger i en virksomhet etter personopplysningslovens § 18, 1. <br></br>
                    ledd. PDR Partner Marcin Strychalski har gitt denne informasjonen i denne erklæringen, og vil henvise til den ved eventuelle forespørsler. De som er registrert<br></br>
                    i en av PDR Partner Marcin Strychalski sine systemer har rett på innsyn i egne opplysninger. Vedkommende har også rett til å be om at uriktige, ufullstendige<br></br>
                    eller opplysninger PDR Partner Marcin Strychalski ikke har adgang til å behandle blir rettet, slettet eller supplert. Krav fra den registrerte skal besvares kostnadsfritt<br></br>
                    og senest innen 30 dager. For å be om innsyn, sletting, endring eller supplering kan du sende en e-post til verksted@pdrpartner.no<br></br> </p>

            <Footer/>
        </div>
    );
}