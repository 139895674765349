import Footer from "../components/footer";
import Menu from "../components/menu";
import Form from "../components/form";

export default function Pristilbud () {
    return (
        <section style={{overflowX: "hidden", width: "100%"}}>
            <Menu/>
            <div className="pristilbud-background">
                <Form/>       
            </div>
            
        </section>
        
    );
}