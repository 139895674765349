import React from 'react';
import * as Label from '@radix-ui/react-label';

const Field = (props) => (
  <div style={{ display: 'flex', flexWrap: 'wrap', gap: 15, alignItems: 'center'}}>
    <Label.Root className="LabelRoot" htmlFor={props.text}></Label.Root>
    <input className="input" style={{ paddingLeft: "10px" }} type={props.type ? props.type : "text"} id="display" name={props.name} placeholder={props.default} onChange={(e) => props.onChange(e)} />
  </div>
);

export default Field;