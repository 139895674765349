import React, { useState, useEffect } from 'react';
import ProductPhotosField from './ProductPhotosField';

export default function Modal({ show, onClose, FieldValue, fieldOnChange, setSkade, setClickPositions, currentAngleIndex, x, y, angles }) {
    const [currentPage, setCurrentPage] = useState(0);
    const [isVisible, setIsVisible] = useState(false);
    const [photos, setPhotos] = useState([]);
    const [message, setMessage] = useState("");

    useEffect(() => {
        if (show) {
            setTimeout(() => setIsVisible(true), 10); // Delay to trigger the CSS transition
            document.body.style.overflow = 'hidden';  // Disable background scrolling
        } else {
            setIsVisible(false);
            document.body.style.overflow = 'auto';  // Enable background scrolling when modal is hidden
        }
        return () => {
            document.body.style.overflow = 'auto';  // Cleanup on unmount
        };
    }, [show]);

    const pages = [
        { title: "Introduksjon", content: "For å kunne gi deg et nøyaktig pristilbud, trenger vi klare bilder av skaden. Vi anbefaler at du sender oss 2-4 bilder av skaden. Se gjerne eksemplene nedenfor for veiledning." },
        { title: "Send Bilder", content: "" },
    ];

    const handleNext = (e) => {
        e.preventDefault();
        if (currentPage < pages.length - 1) {
            setCurrentPage((prev) => prev + 1);
        }
    };

    const handlePrevious = (e) => {
        e.preventDefault();
        if (currentPage > 0) {
            setCurrentPage((prev) => prev - 1);
        }
    };

    const handleCreate = (e) => {
        e.preventDefault();
        setSkade((prevSkade) => [
            ...prevSkade,
            {
                photos: photos,
                image: currentAngleIndex,
                x: x,
                y: y,
                message: message,
            },
        ]);
        onClose();
        setClickPositions((prevPositions) => ({
            ...prevPositions,
            [angles[currentAngleIndex]]: [
                ...(prevPositions[angles[currentAngleIndex]] || []),
                { x, y },
            ],
        }));
    };

    const changeValue = (value) => {
        setMessage(value);
    };

    if (!show) {
        return null;
    }

    return (
        <div style={styles.modalOverlay}>
            <div
                style={{
                    ...styles.modalContent,
                    opacity: isVisible ? 1 : 0,
                    transform: isVisible ? "translateY(0)" : "translateY(-20px)",
                    transition: "opacity 500ms ease, transform 500ms ease",
                }}
            >
                <button onClick={onClose} style={styles.closeButton}>
                    &times;
                </button>
                <h2>{pages[currentPage].title}</h2>
                <p style={{ maxWidth: "650px" }}>{pages[currentPage].content}</p>

                {currentPage === 0 ? (
                    <>
                        <h2 style={{ marginBottom: "50px" }}>Eksempel</h2>
                        <section style={{
                        display: "flex",
                        flexDirection: window.innerWidth <= 768 ? "column" : "row", // Stack items vertically on mobile
                        alignItems: "center",  // Center items in mobile view
                        }}>
                        <div
                            style={{
                            width: window.innerWidth <= 768 ? "250px" : "250px",  // Adjust width for mobile
                            height: "250px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: "url(./test-close.jpg)",
                            margin: "5px",
                            marginTop: "20px",
                            }}
                        >
                            <p style={{ position: "relative", bottom: "60px", fontSize: window.innerWidth <= 768 ? "12px" : "15px" }}>Bildet fra nærheten</p>
                        </div>
                        <div
                            style={{
                            width: window.innerWidth <= 768 ? "250px" : "250px",  // Adjust width for mobile
                            height: "250px", // Adjust height for mobile
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: "url(./test-medium.jpg)",
                            margin: "5px",
                            marginTop: "20px",
                            }}
                        >
                            <p style={{ position: "relative", bottom: "60px", fontSize: window.innerWidth <= 768 ? "12px" : "15px" }}>50cm unna</p>
                        </div>
                        <div
                            style={{
                            width: window.innerWidth <= 768 ? "250px" : "250px",  // Adjust width for mobile
                            height: "250px",
                            backgroundSize: "contain",
                            backgroundRepeat: "no-repeat",
                            backgroundImage: "url(./test-far.jpg)",
                            margin: "5px",
                            marginTop: "20px",
                            }}
                        >
                            <p style={{ position: "relative", bottom: "60px", fontSize: window.innerWidth <= 768 ? "12px" : "15px" }}>1 meter unna</p>
                        </div>
                        </section>

                    </>
                ) : currentPage === 1 ? (
                    <>
                        <div>
                            <ProductPhotosField onChange={setPhotos} value={photos} />
                        </div>
                        <h1 style={{ fontSize: "23px" }}>Legg til melding (valgfri)</h1>
                        <textarea
                            className="input"
                            placeholder="Valgfri melding"
                            name="message"
                            style={{ padding: "15px", margin: "50px", height: "70px", width:"90%", marginLeft:"0px", margin: "50px" }}
                            value={message}
                            onChange={(e) => changeValue(e.target.value)}
                        />
                    </>
                ) : (
                    <div></div>
                )}

                <div style={styles.buttonContainer}>
                    <button
                        onClick={handlePrevious}
                        style={{
                            ...styles.navButton,
                            visibility: currentPage === 0 ? "hidden" : "visible",
                        }}
                    >
                        Tilbake
                    </button>

                    <button
                        onClick={handleNext}
                        style={{
                            ...styles.navButton,
                            display: currentPage === pages.length - 1 ? "none" : "inline-block", position:"relative", bottom:"85px", margin:"auto"
                        }}
                    >
                        Neste
                    </button>

                    <button
                        onClick={handleCreate}
                        style={{
                            ...styles.navButton,
                            display: currentPage === pages.length || currentPage === 0 ? "none" : "inline-block",
                        }}
                    >
                        Legg til skaden
                    </button>
                </div>
            </div>
        </div>
    );
}

const styles = {
    modalOverlay: {
        position: "fixed",
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: "rgba(0, 0, 0, 0.5)",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        zIndex: 1000,
    },
    modalContent: {
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "8px",
        width: "fit-content",
        maxWidth: "90%",
        maxHeight: "90vh", // Set max height for scrollable content
        overflowY: "auto", // Enable vertical scroll
        overflowX: "hidden",
        boxShadow: "0 2px 10px rgba(0, 0, 0, 0.1)",
        textAlign: "center",
        opacity: 0, // Initial opacity for transition
        transform: "translateY(-20px)", // Initial transform for transition
        position: "relative", // Added for close button positioning
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "space-between",
        marginTop: "20px",
    },
    navButton: {
        padding: "10px 20px",
        backgroundColor: "#007bff",
        color: "#fff",
        border: "none",
        borderRadius: "4px",
        cursor: "pointer",
        margin:"10px"
    },
    closeButton: {
        position: "absolute",
        top: "10px",
        right: "-110px",
        background: "none",
        border: "none",
        fontSize: "20px",
        cursor: "pointer",
    },
};
