import Footer from "./footer";
import Menu from "./menu";

export default function LakkrensPage(){
    console.log("XD")
    return (
        <div>
             <Menu/>  
             <div style={{height:"138px"}}></div>  
             <section className="main-section-article">
                <h1 className="heading-1" style={{marginTop:"100px", fontWeight:"700", marginBottom:"5px"}}>Lakkrens og Polering</h1>
                <p style={{marginTop:0, marginBottom:0, fontSize:"14px"}}>August 17, 2024</p>
                <div className="lakkrens-article"></div>
                <h2 className="heading-2">Innholdet</h2>
                <ul>
                    <li style={{left:"0"}}>Introduksjon</li>
                    <li style={{left:"0"}}>Hva innebærer lakkrens?</li>
                    <li style={{left:"0"}}>Hvorfor er lakkrens viktig?</li>
                    <li style={{left:"0"}}>Konklusjon</li>
                </ul>
                <h2 className="heading-2">Introduksjon</h2>
                <p>Når det kommer til bilpleie, er lakkrens en av de mest essensielle prosessene for å opprettholde bilens estetiske verdi og beskytte lakken over tid. Selv om mange bileiere kanskje tenker at en god håndvask er nok for å holde bilen ren, er sannheten at det er mange typer forurensninger som en vanlig vask ikke kan fjerne. Dette er hvor lakkrens kommer inn i bildet, og det er en tjeneste vi tilbyr for å sikre at lakken din er i topp stand, klar for videre behandling som polering, voksing, eller påføring av coating. </p>
                <h2 className="heading-2">Hva innebærer lakkrens?</h2>
                <p>Lakkrens er en prosess som går utover vanlig vask for å fjerne inngrodde forurensninger som veistøv, tjære, kvae, flyverust, overspray, og gammel voks. Disse forurensningene kan være usynlige for det blotte øye, men de kan kjennes som en ru overflate når du stryker hånden over lakken. Hvis de ikke fjernes, kan de skade lakken, redusere bilens glans og i verste fall føre til korrosjon.</p>
                <strong><p>Lakkrens kan utføres på flere måter:</p></strong>
                <ul>
                <li style={{marginBottom:"35px", left:"0"}}>
                <strong>Kjemisk lakkrens: </strong> Bruk av spesialiserte kjemikalier som flyverustfjerner og tjærefjerner for å oppløse forurensninger uten å skade lakken. Passer godt for dyprens og kan utføres selv uten påfølgende polering.</li>
                
                <li style={{marginBottom:"35px", left:"0"}}>
                <strong>Manuell lakkrens: </strong> Innebærer bruk av clay bar eller lignende verktøy som fysisk fjerner små partikler fra lakken. Etterlater en ren overflate, men krever polering for å fjerne fine riper.</li>

                <li style={{marginBottom:"35px", left:"0"}}>
                <strong>Maskinell lakkrens: </strong> Utføres med poleringsmaskin og rensemidler eller clay-pads. Det er en raskere metode, men krever presisjon for å unngå skade på lakken.</li>
                </ul>
                <h2 className="heading-2">Hvorfor er lakkrens viktig?</h2>
                <p>Lakkrens er en nødvendig forberedelse før polering og andre behandlinger. Den fjerner forurensninger som ellers kan slipes dypere inn i lakken under polering, noe som kan forårsake skade. I tillegg gir lakkrens en ren og glansfull overflate, som forbedrer bilens utseende og bevarer dens verdi.</p>
                <strong><p>Fordelene med lakkrens hos oss</p></strong>
                <ul>
                    <li style={{marginBottom:"35px", left:"0"}}>
                        <strong>Ekspertise:</strong> Våre teknikere bruker de beste produktene og metodene for en trygg og effektiv rens.</li>
                    <li style={{marginBottom:"35px", left:"0"}}>
                        <strong>Skreddersydd: </strong> Vi tilpasser lakkrensen etter bilens tilstand for best resultat. </li>
                    <li style={{marginBottom:"35px", left:"0"}}><strong>Beskyttelse:</strong> Rens legger grunnlaget for videre behandlinger som beskytter lakken over tid. </li>
                </ul>
                <h2 className="heading-2">Konklusjon</h2>
                <p style={{marginBottom:"100px"}}>Lakkrens er mer enn bare et ekstra steg i bilpleien—det er en nødvendighet for å bevare bilens utseende og verdi. Ved å velge vår lakkrens-tjeneste, investerer du i bilens fremtid. Vi sørger for at lakken blir helt ren, fri for skadelige forurensninger, og klar for videre behandlinger som polering og voksing. Dette resulterer i en mer skinnende, godt beskyttet bil som vil holde seg flott i mange år fremover.</p>

             </section>
             <Footer/>       
        </div>
    );
}