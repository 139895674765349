
export default function SmallPicture (props){


    
    return (
        <div style={{width: props.width, height: props.height, margin:"0 auto", marginBottom: "50px"}} id="small-picture">
            <img style={{width: "100%", height: "100%"}} src={props.picture}>
            </img>
            <div className="name-tag" style={{width: props.width, fontSize: props.font}}>
                <p style={{position:"relative", top: props.topText}}>{props.text}</p>
            </div>
        </div>
    );
}