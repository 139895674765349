import image from "../assets/desktop/black-logo.png";

export default function AboutPoints (){
    return (
        <div className="points-container">
            <div>
                <img src={image}></img>
                <h1>Raskere</h1>
            </div>

            <div>
                <img src={image}></img>
                <h1>Uten lakkering</h1>
            </div>

            <div>
                <img src={image}></img>
                <h1>Miljøvennlig</h1>
            </div>
        </div>
    );
}