import Menu from "../components/menu"
import Footer from "../components/footer"
import React from 'react';
import ReactBeforeSliderComponent from 'react-before-after-slider-component';
import 'react-before-after-slider-component/dist/build.css';
import mg from "../assets/desktop/MgBig.png"


export default function Prosjekt(props){

    const FIRST_IMAGE = {
        imageUrl: "./images/bmw2.png"
      };
      const SECOND_IMAGE = {
        imageUrl: './images/bmw.png'
      };

    return(
        <div style={{overflowX:"hidden"}}>
            <Menu/>
            <div style={{height:"100vh", width:"100wh"}}>
                <div className="projsekter-title-image">
                    
                </div>
                 <h1 className="prosjekter-title">TITLE</h1>
            </div>
    
            <section className="before-after">
                <ReactBeforeSliderComponent
                    firstImage={FIRST_IMAGE}
                    secondImage={SECOND_IMAGE}
                    currentPercentPosition = "75"
                />
                <div className="before-after-text">
                    <div className="for">Før</div>
                    <div className="for" id="etter">Etter</div>
                </div>
            </section>
            <section className="prosjekt-text">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit. Donec suscipit imperdiet velit id tempor. <br></br>
                     Sed non massa tortor. Lorem ipsum dolor sit amet, consectetur adipiscing elit.  <br></br>
                     Donec suscipit imperdiet velit id tempor. Sed non massa tortor. Lorem ipsum dolor sit amet,  <br></br>
                     consectetur adipiscing elit. Donec suscipit imperdiet velit id tempor. Sed non massa tortor.
                </p>
                <div className="prosjekt-pictures-container">
                    <div className="item"><img className="item-picture" src={mg}/></div>
                    <div className="item"><img className="item-picture" src={mg}/></div>
                    <div className="item"><img className="item-picture" src={mg}/></div>
                    <div className="item"><img className="item-picture" src={mg}/></div>
                </div>
                
            </section>
            <Footer/>
        </div>
    );

}