import ContactForm from "../components/contactForm.jsx";
import Menu from "../components/menu"
import Footer from "../components/footer"

export default function ContactPage(){
    return(
        <div style={{overflow:"hidden"}}>
        <Menu/>
        <div style={{width:"100%", height:"85px"}}></div>
        <ContactForm/>
        <Footer/>
        </div>
    );
}