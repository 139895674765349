import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import Menu from './components/menu';
import HomePage from './pages/HomePage';
import {
	createBrowserRouter,
	RouterProvider
} from 'react-router-dom';

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Pristilbud from './pages/Pristilbud';
import routes from './pages/routes';
import ScrollToTop from './components/ScrollToTop';

import ContactPage from './pages/ContactPage';
import OmOssPage from './pages/OmOssPage';
import ProsjekterPage from './pages/ProsjekterPage';
import Prosjekt from './pages/Prosjekt';
import Personvern from './pages/personvern';
import TakkPage from './pages/TakkPage';
import ArticlePage from './components/articlePage';
import LakkrensPage from './components/lakkrensPage';
import InnvendigDyprensPage from './components/RensPage';
import DetailingPage from './components/DetailingPage';



const router = createBrowserRouter(routes);

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Router>
       <ScrollToTop>
       <Routes>
          <Route path="/"element={<HomePage/>}/>
          <Route path="/pristilbud"element={<Pristilbud/>}/>
          <Route path="/kontakt"element={<ContactPage/>}/>
          <Route path="/om-oss"element={<OmOssPage/>}/>
          <Route path="/prosjekter"element={<ProsjekterPage/>}/>
          <Route path="/prosjekt"element={<Prosjekt/>}/>
          <Route path="/personvern"element={<Personvern/>}/>
          <Route path="/takk-for-henvendelsen"element={<TakkPage/>}/>
          <Route path="/lakkeringsfri-bulkoppretting"element={<ArticlePage/>}/>
          <Route path="/lakkrens" element={<LakkrensPage/>}/>
          <Route path='/invendig-rens' element={<InnvendigDyprensPage/>}/>
          <Route path='/detailing' element={<DetailingPage/>}/>
       </Routes>
    </ScrollToTop>
    </Router>
   
     
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
