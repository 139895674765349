import picture1 from "../assets/desktop/picture1.png";
import {Link } from "react-router-dom";

export default function MainService (){
    return(
        <div className="information-container" id="service-container" >
            <div className="column" id="information-column1">
                    <h1 className="h1-over-information-column" style={{marginTop:"120px"}}>Lakkeringsfri Bulkoppretting</h1>
                    <div style={{textAlign:"left", width:"fit-content", margin:"auto"}}>
                        <p className="p-small" id="p-over-information-column" style={{maxWidth:"800px", marginInline:"10px"}}>
                        Få et gratis pristilbud ved å bruke skjemaet på vår nettside. Fyll ut nødvendig informasjon om skadene på kjøretøyet ditt, og last opp bilder for en nøyaktig vurdering.
                        </p>
                    </div>
                    <div className="buttons-information" style={{margin:"auto", width:"100%", maxWidth:"640px", marginTop:"70px"}}>
                      <Link style={{width:"fit-content", margin:"auto"}} to={"/pristilbud"}><button style={{marginInline:"10px"}} >Få et pristilbud</button></Link>  
                       <Link style={{width:"fit-content", margin: "auto"}} to={"/lakkeringsfri-bulkoppretting"}> <button style={{marginInline:"10px"}}>Les mer</button></Link>
                    </div>
            </div>
        </div>
    );
}